// import packages
import { useState, useEffect } from 'react';
import { motion } from 'motion/react';

// import assets
import CheatSheet from '../../../public/assets/sec_filing_cheat.jpg';

// import hooks
import { useAction } from '../../hooks/useAction.jsx';

export const Landing = () => {
	const [email, setEmail] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [message, setMessage] = useState('');

	// access hook
	const { actionQuery } = useAction();

	useEffect(() => {
		let timeout;
		if (message || errorMessage) {
			// Clear the message and success state after 5 seconds
			timeout = setTimeout(() => {
				setMessage('');
				setEmail('');
				setErrorMessage('');
			}, 5000);
		}

		// Cleanup function to clear the timeout if the component unmounts
		return () => clearTimeout(timeout);
	}, [message, errorMessage]);

	const handleSignup = async () => {
		try {
			// Send the email to the backend API
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/signup`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email }),
			});

			// data from server
			const data = await response.json();

			if (response.ok) {
				// Clear the email field and set success message
				setEmail('');
				setMessage(data.message || 'Thank you for signing up! Please check your email to verify your subscription.');

				// update the tracker
				await actionQuery('sign_up');
			} else {
				setErrorMessage(data.message || 'Error during sign-up. Please try again.');
			}
		} catch (error) {
			setErrorMessage('An error occurred. Please try again.');
			console.error(error);
		}
	};

	const RenderMessage = () => {
		if (errorMessage) {
			return (
				<motion.p
					initial={{ opacity: 0, y: -20 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 10 }}
					transition={{ duration: 0.5, ease: 'easeOut' }}
					className="text-zinc-900 bg-red-400 px-4 py-2 my-2 font-['IBM Plex Sans'] text-[16px]">
					{errorMessage} ❌
				</motion.p>
			);
		} else if (message) {
			return (
				<motion.p
					initial={{ opacity: 0, y: -20 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 10 }}
					transition={{ duration: 0.5, ease: 'easeOut' }}
					className="text-zinc-900 bg-luif-cta-2 px-4 py-2 my-2 font-['IBM Plex Sans'] text-[16px] break-words">
					{message} ✅
				</motion.p>
			);
		}
	};

	return (
		<div>
			<div className="mx-auto px-4 sm:px-0 grid sm:grid-cols-6 mb-12">
				<div></div>

				<div className="bg-[#fbf6ed] mx-0 sm:mx-12 my-12 py-4 col-span-4 overflow-clip z-10">
					{RenderMessage()}
					<div className="grid grid-cols-1 sm:grid-cols-2 ml-4">
						<div className="space-y-4">
							<h1 className="text-[28px] sm:text-[32px] font-['Poppins'] font-bold">
								Master SEC Filings & <span className="block"> Invest Smarter</span>
							</h1>
							<div className="bg-[#fde74c] -ml-8">
								<div className="ml-8">
									<h1 className=" text-[28px] sm:text-[32px] font-['Poppins'] font-bold">Without the Headache.</h1>
								</div>
							</div>
							<p className="text-[16px] sm:text-[20px] font-['IBM Plex Sans'] font-light">
								SEC filings hold the truth about a company— <span className="block">this guide helps you find it fast.</span>
							</p>
							<motion.input
								type="email"
								autoComplete="email"
								name="user_email"
								id="user_email"
								value={email}
								onChange={e => setEmail(e.target.value)}
								className="border border-gray-900 w-5/6 py-2 px-4 text-[12px] sm:text-[16px] font-['IBM Plex Sans']"
								placeholder="Enter your email for instant access"
								onKeyDown={e => {
									if (e.key === 'Enter') {
										handleSignup();
									}
								}}
								initial={{ scale: 1 }}
								whileFocus={{
									scale: 1.01,
									boxShadow: '0px 0px 15px #d9f99d',
								}}
								transition={{
									type: 'spring',
									stiffness: 200,
									damping: 15,
								}}
								required
							/>

							<motion.button
								whileHover={{ scale: 0.99 }}
								whileTap={{
									scale: 1,
									transition: { type: 'spring', stiffness: 300, damping: 15 },
								}}
								onClick={() => handleSignup()}
								className="block bg-luif-cta-2 py-4 px-4 font-bold text-['Poppins'] text-[16px]">
								Send Me My Free Guide
							</motion.button>
						</div>
						<div className="h-80 sm:h-32 w-auto">
							<img src={CheatSheet} alt="sec filing cheat sheet by lvlupinfinance" className="z-20 relative top-0 left-16 sm:left-12" />
						</div>
					</div>
				</div>

				<div></div>
			</div>
		</div>
	);
};
