// import packages
import { useState } from 'react';

export const useFeedback = () => {
	const [feedback, setFeedback] = useState({
		hasSubmitted: false,
		action: null,
		likes: 0,
		dislikes: 0,
	});
	const [error, setError] = useState('');

	const submitFeedback = async ({ action }) => {
		try {
			const pageUrl = window.location.pathname;

			const response = await fetch(`${process.env.REACT_APP_ANALYTICS_URI}/luif/feedback`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ pageUrl, action }),
			});

			if (!response.ok) {
				throw new Error(`Error: ${response.statusText}`);
			}

			const data = await response.json();

			setFeedback({
				hasSubmitted: true,
				action,
				likes: data.likes,
				dislikes: data.dislikes,
			});
		} catch (err) {
			console.error(err);
			console.error('Error submitting feedback:', err);
			setError('Error submitting feedback.');
		}
	};

	return { feedback, submitFeedback, error };
};
