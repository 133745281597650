// import packages
import { useEffect, useState } from 'react';

// import components
import { Navbar } from '../navbar/index.jsx';
import { Footer } from '../footer/index.jsx';
import { StockChartLoading } from '../stock_chart_loading/index.jsx';

// import hooks
import { useAction } from '../../hooks/useAction.jsx';

export const Report001 = () => {
	// access hook
	const { actionQuery } = useAction();

	// Helper function to store token in localStorage
	const storeToken = token => {
		localStorage.setItem('userToken', token);
	};

	// Helper function to remove token from localStorage
	const removeToken = () => {
		localStorage.removeItem('userToken');
	};

	// Helper function to get token from localStorage
	const getToken = () => {
		localStorage.getItem('userToken');
	};

	// Helper function to check token expiration
	const isTokenExpired = token => {
		try {
			const payload = JSON.parse(atob(token.split('.')[1]));
			const currentTime = Math.floor(Date.now() / 1000);
			return payload.exp < currentTime;
		} catch {
			return true;
		}
	};

	// Extract and store token from URL
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const token = params.get('token');

		if (token) {
			storeToken(token);
		}
	}, [window.location.search, window.location.href]);

	const downloadReport = async () => {
		const token = window.localStorage.getItem('userToken');

		if (!token || isTokenExpired(token)) {
			removeToken();
			window.location.replace('/not-authorized');
			return;
		}

		try {
			const settings = {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			};

			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/download-report`, settings);
			if (!response.ok) {
				console.error('Report not downloaded:', response);
				throw new Error('Report not downloaded.');
			}

			// Convert the response into a Blob
			const blob = await response.blob();
			// Create a URL for the blob and simulate a click to download the file
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'lvlupinfinance_sec_filing_cheat_sheet.pdf');
			document.body.appendChild(link);
			link.click();
			link.remove();
			window.URL.revokeObjectURL(url);

			// update the tracker
			await actionQuery('sec_filing_cheat_sheet');
		} catch (error) {
			console.error('Failed to download report:', error);
			// Optionally remove the token or redirect the user on failure
		}
	};

	return (
		<div className="">
			<Navbar />
			<div className="text-slate-50 my-16 text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
				<div></div>
				<div className="sm:col-start-2 sm:col-span-4 break-words">
					<div className="space-y-4">
						<h1 className="text-[36px] font-['Poppins']">You're All Set! 🎉</h1>
						<p className="text-[20px] font-['IBM Plex Sans'] font-light">
							Thank you for verifying your email and taking the next step in mastering SEC filings! We're thrilled to have you on this journey with us.
						</p>
						<p className="text-[20px] font-['IBM Plex Sans'] font-light">
							Your SEC Filing Cheat Sheet is ready to download—your go-to guide for breaking down complex financial reports with confidence.
						</p>
					</div>

					<div className="my-4">
						<button onClick={() => downloadReport()} className="bg-luif-cta-2 px-4 py-4 text-zinc-900 font-['IBM Plex Sans'] font-bold">
							Download Now
						</button>
					</div>

					<div className="font-[20px] font-['IBM Plex Sans'] space-y-4">
						<div className="space-y-2">
							<p>This is just the beginning! We can’t wait to bring you more insights and resources to help you navigate the world of finance like a pro.</p>
							<p>Happy learning! 🚀</p>
						</div>
						<hr />
						<p className="text-[12px] text-gray-400">
							If you're having trouble downloading your report, please contact us at lvlupinfinance@gmail.com to report your issue.
						</p>
					</div>
				</div>
				<div></div>
			</div>
			<Footer />
		</div>
	);
};
