export const useAction = () => {
	const actionQuery = async actionName => {
		try {
			const settings = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ actionName }),
			};
			const response = await fetch(`${process.env.REACT_APP_ANALYTICS_URI}/luif/action-tracker`, settings);

			if (!response.ok) {
				throw new Error(`Error: Unable To Update Tracker.`);
			}

			const responseResults = await response.json();

			// results should be good.
		} catch (error) {
			console.error(error);
			console.error('Error tracking:', error);
		}
	};

	return { actionQuery };
};
